<script>

//////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Search widget for the main navigation bar
//  
//  Variant of NavMainSearch.
//
//  Author Alex Lowe
//
//////////////////////////////////////////////////////////////////////////////////////////////////

import { defineComponent, onMounted, ref, computed, watch, inject } from 'vue'
import { useRouter } from 'vue-router'
import { sleep } from '@kit/utils/Sleep'
import Modal from '@kit/components/Modal.vue'
import IconButton from '@kit/components/IconButton.vue'
import SearchResults from '@kit/components/SearchResults.vue'
import { mergeClassesTheme } from '@kit/utils/Formats'
import { useStore } from 'vuex'
import { bodyScrollOff } from '@kit/utils/Scroller'
import Config from '@kit/utils/Config'



/**  
 
  Desktop theme options
 
  export const searchWidgetMain = {

    //the number of results per page
    resultsPerPage: 10,
  
    //The height of the widget
    mainWidgetHeight:60,

    //The height of the drop-shadow at the bottom. 
    shadowAdjustment:5,

    //Show the thumbnails
    showThumbnails: false,
  
    //the options for the navigation item in the top menu.
    navComponent: {
      button: blueButton,

      //the mergeable classes for the navigation button
      outerClasses: "my-class",
    },

    //the place to put the classes and styles for the search widget modal
    searchComponent: {

      //What style will the searchbar be in the nav? 
      //If true, then it will open up a modal with a searchbox there,
      //if false, then there will be a little searchbox that drops out 
      //of the nav. Almost the same options are available in either case
      modalMode: true,

      //the mergeable classes for the headlines
      mainHeadlineClasses: "some-class",
      topHeadlineClasses: "some-other-class", //modalMode only.

      //the mergeable classes for the loading class over the input box
      loadingGlassClasses:"some classes",

      //The two headlines in the search modal window
      topHeadline: "Search",
      mainHeadline: "Search our website",

      //the modal theme for the search widget
      modal: searchWidgetModal, //modalMode only
      
      //the mergeable classes for the wrapper around the search elements
      searchBoxUIContainerClasses: "some class",
      searchBoxWrapperClasses: "some class",
      searchBoxClasses: "some class",

      //the mergeable classes for the wrappers and elements of the completion table
      completionUIContainerClasses: "some class",
      completionWrapperClasses: "some class",
      completionResultClasses: "some class",

      //the search button
      searchButton: {...blueButton, text:"Search", outerClasses:"seven-tribes-search-modal-search-button" },
      




    
    },

    //The options for the search-results.
    resultsCompnent: {

      //the route. Used for if you want the results to appear on another page
      route: "/search",

      //the theme for the search
      searchResults: searchResults,

      //the modal theme, for use in the event that route is null
      //modal: searchResultsModal
    }

  }

  Mobile theme options

  export const searchWidgetMobile = {

    //extand from the options for the main one
    ...searchWidgetMain,

    //needs to be here. tells the ui that this is a mobile theme.
    isMobile: true,

    //the options for the search component in the side-navigation drawer.
    navComponent: {
      button: myButtonTheme,

      //the mergeable classes for the outer-wrapper
      outerClasses: "my-class",

      //the mergeable classes for the headline
      headlineClasses: "some class",

      //the mergeable classes for the wrapper around the search elements
      searchBoxUIContainerClasses: "some other classes",

      the mergeable classes for the immediate wrapper around the search box. The search box is its only contents
      searchBoxWrapperClasses: "some other classes",

      //the mergeable classes for the input element itself
      searchBoxClasses: "some other classes",

      //the mergeable classes for the ui continer around the completion results
      completionUIContainerClasses: "some classs",

      //the mergeable  classes for the completion container
      completionWrapperClasses:"some class",

      //the mergeable  classes for the completion result itsms
      completionResultClasses:"some class",

      //the position of the completion options.
      completionUnderPosition: true, //false,

      //the mergeable classes for the loading class over the input box
      loadingGlassClasses:"some classes"
    },
  } 



**/


//The default configuration object.
const defaultConfig = new Config({
  navComponent: {
    headline: "Search"
  },
  isMobile: false
})


export default defineComponent({
  name: "NavMainSearch",
  props: {
    "theme": { required:true },
    "active": { required:false, default:false }
  },
  setup(props, context) {

    const router = useRouter()
    const store = useStore()
    const inMobile = computed(() => store.state.inMobile)
    const searchModalOpen = ref(false) 
    const searchWidgetOpen = ref(false)  
    const mainSearchButton = ref(null) 
    const mobileSearchButton = ref(null)

    const themer = inject("themer")

    const { getProp, setProp, reskin } = themer({props, defaultConfig })

    let cseInputElement = null
    let cseCompletionContainer = null

    const searchResults = ref(null)
    const modalOpen = ref(false)
    const loading = ref(false)
    const searchResultsList = ref(null)
    const currentSearchTerm = ref(null)
    const mounted = ref(false)

    const mobileInput = ref(null)
    const desktopInput = ref(null)

    const mobileCompletion = ref(null)
    const desktopCompletion = ref(null)

    const mobileCompletionWrapper = ref(null)
    const desktopCompletionWrapper = ref(null)

    const uiAllowsCompletionContainer = ref(false)

    const completionResults = ref([])

    //Are we using another page for the search results
    const useSearchRoute = computed(() => {
      return !!getProp('resultsComponent.route')
    })

    //is this component actiive? It's active if the drawer is open, or 
    //if the search modal is open.
    const active = computed(() => {
      return (props.active && mounted.value) || 
      (searchModalOpen.value && mounted.value) || 
      (searchWidgetOpen.value && mounted.value)
    })

    //Watch the active. fire off the activate/deactivate functions.
    watch(active, (newVal, oldVal) => {
      if(newVal && !oldVal) {
        activate()
      } else 
      if(!newVal && oldVal) {
        deactivate()
      }
    })


    const getCSECompletionContainer = () => {
      if(!cseCompletionContainer) {
        cseCompletionContainer = document.querySelector('.gsc-completion-container')
      }
      return cseCompletionContainer
    }
    const getCSEInputElement = () => {
      if(!cseInputElement) {
        cseInputElement = document.querySelector(".gsc-search-box-tools tbody > tr > td > input")
      }
      return cseInputElement
    }


    const waitForCSEEInputBox = async() => {
      const googleCSE = getCSEInputElement()

      //if inactive, then return whatever we have.
      if(!active.value) {
        return googleCSE
      }

      if(googleCSE) {
        return googleCSE
      } else {
        await sleep(200)
        return await waitForCSEEInputBox()
      }
    }

    const showCompletionMobile = computed(() => {
      return (uiAllowsCompletionContainer.value && completionResults.value.length > 0 && getProp('isMobile') == true)
    })
    const showCompletionDesktop = computed(() => {
      return (uiAllowsCompletionContainer.value && completionResults.value.length > 0 && !getProp('isMobile'))
    })


    //There's an annoying thing where google cse will turn body scrolling back
    //"on" by giving it position="static" style. So watch for that behavior and negate 
    //it as soon as possible. We do this below in the doSearch function
    const stopGCEFromTurningOnScroll = () => {
      let interval = setInterval(() => {
        if(document.body.style.position != "fixed") {
          clearInterval(interval)
          interval = null
          bodyScrollOff({saveScroll:true})
        }
      },100)
    }


    //Perform the search from the google CSE endpoint
    const doSearch = async(searchTerm) => {

      //Dismiss the completion container.
      //It won't just go away on its own.
      uiAllowsCompletionContainer.value = false
   
      //if we're supposed to use another page to display the search results, then
      //we're going to use that page.
      if(useSearchRoute.value) {

        loading.value = true
        await sleep(1000)        
        searchModalOpen.value = false
        router.push(`${getProp('resultsComponent.route')}/${encodeURIComponent(searchTerm)}/1`)
        loading.value = false
      
      } else {

        loading.value = true

        await searchResultsList.value.searchWithTerm(searchTerm)
        const data = searchResultsList.value.getSearchResults()

        if(data && !data.error) {
          //There's an annoying thing where google cse will turn body scrolling back
          //"on" by giving it position="static" style. So watch for that behavior and negate 
          //it as soon as possible.
          if(getProp('isMobile')) {
            stopGCEFromTurningOnScroll()
          }

          modalOpen.value = true
        } else {
          console.log("Error: getSearchResults failed")
        }
        loading.value = false      
      }
    }




    /////////////////////
    // Event Listeners //
    /////////////////////


    //when one of the options is clicked, then we have a new search term.
    const processOptionsClick = async(e) => {
      e.stopPropagation()

      if(loading.value) {
        return
      }
      if(!active.value) {
        return
      }

      const el1 = e.target
      const el2 = el1.parentNode 

      const searchText = el1.getAttribute("data-searchtext") || el2.getAttribute("data-searchtext")

      if(searchText) {        
        inputElement.value.value = searchText
        doSearch(searchText)
      }
    }

    //whenever the input changes, we're going to look for the search-options
    const processInputChange = async(e) => {
      e.stopPropagation()

      if(active.value && !loading.value) {
        //ALEX-GCE figure out something better to do here than wait 
        //https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
        await sleep(500)

        const cse = getCSECompletionContainer()
        const results = []
        if(cse) {
          const cseResults = cse.querySelectorAll("table span")
          for(let i=0; i<cseResults.length; i++) {
            const node = cseResults[i]
            results.push({html:node.innerHTML, text:node.innerText, el:node})
          }
        }
        completionResults.value = results

      }
    }

    //if the user clicked enter, then we're going to perform the search.
    //else, whatever the user 
    const handleInputKeyup = async(e) => {
      e.stopPropagation()

      if(loading.value) {
        return
      }

      //if it's the enter button, then perform the search.
      if (e.key === "Enter") {
        handleSearchButtonClick()
      } 
      
      //else, if it's a single character, then go ahead and append it.
      else {
        const cseInput = getCSEInputElement()
        const newValue = e.target.value
     
        //Here's how we dispatch events to the 
        cseInput.value = newValue
        cseInput.dispatchEvent(new Event("change"))
        cseInput.dispatchEvent(new Event("input"))
        cseInput.dispatchEvent(new Event("keydown"))
        uiAllowsCompletionContainer.value = true
      }
    }

    //If the Go button was clicked, then perform the search as well.
    const handleSearchButtonClick = async() => {

      if(loading.value) {
        return
      }

      //if we're not in a modal mode, then we're going 
      //to show the loading state for just a moment.
      if(!getProp('searchComponent.modalMode')) {
        loading.value = true
        await sleep(500)
      }

      const inputBox = getCSEInputElement()
      if(inputBox) {
        const searchTerm = inputBox.value 
        doSearch(searchTerm)
      }
    }

    const inputElement = computed(() => {
      if(getProp('isMobile')) {
        return mobileInput.value
      } else {
        return desktopInput.value
      }
    })


    const activate = async() => {
      loading.value = true

      document.addEventListener("click", documentClick)

      await waitForCSEEInputBox()

      loading.value = false
    }
    const deactivate = () => {
      document.removeEventListener("click", documentClick)
    }


    //when the input is clicked, we're going to stop the propagation so that the 
    //click doesn't get captured by the document.
    const inputClick = (e) => {
      e.stopPropagation();
    }
    //same for this.
    const handleClickOnNonmodalSearchWidget = (e) => {
      e.stopPropagation()
    }

    //if a click makes it up to the document, then we're going to dismiess the completion results,
    //and if we're using the search widget instead of the modal, then we're going to dismiss it too.
    const documentClick = (e) => {
      if(!getProp('searchComponent.modalMode')) {
        searchWidgetOpen.value = false
      }
      uiAllowsCompletionContainer.value = false
    }

    onMounted(async() => {
      mounted.value = true

      await sleep(500)

      const inputDOMNode = inputElement.value

      inputDOMNode.addEventListener("input", processInputChange)
      inputDOMNode.addEventListener("keyup", handleInputKeyup)
      inputDOMNode.addEventListener("click", inputClick)

      const completion = getProp('isMobile') ? mobileCompletion.value : desktopCompletion.value
      completion.addEventListener("click", processOptionsClick)

      const wrapper = getProp('isMobile') ? mobileCompletionWrapper.value : desktopCompletionWrapper.value
      const isMobile  = getProp('isMobile')

      //the completion is under the input, but if the theming says otherwise then put it 
      //above the input.
      if(isMobile && !getProp('completionUnderPosition')) {
        wrapper.style.top = "0px"
        wrapper.style.height = "1px"
        completion.style.top = "auto"
        completion.style.bottom = "0%"
      }

    }) 

    const totalWidgetHeight = computed(() => {
      return `${getProp('mainWidgetHeight') + getProp('shadowAdjustment')}px`
    })
    const widgetHeight = computed(() => {
      return `${getProp('mainWidgetHeight')}px`
    })
    const shadowHeight = computed(() => {
      return `${getProp('shadowAdjustment')}px`
    })


    //Open or close the search-widget.
    const mainSearchNavButtonPressed = async() => {
      if(getProp('searchComponent.modalMode')) {
        searchModalOpen.value = !searchModalOpen.value
      } else {
        searchWidgetOpen.value = !searchWidgetOpen.value
      }
    }

    /////////////
    //  A P I  //
    /////////////

    const reskinSearchButton = (skinObj) => {
      if(getProp('isMobile')) {
        mobileSearchButton.value.reskin(skinObj)
      } else {
        mainSearchButton.value.reskin(skinObj)
      }
    }

    context.expose({ reskinSearchButton, reskin })

    return { 
      getProp, 
      setProp,
      mainSearchButton,
      mobileSearchButton,
      reskinSearchButton,
      inMobile,
      mounted,
      mobileCompletion,
      mobileCompletionWrapper,
      desktopCompletion,
      desktopCompletionWrapper,
      mobileInput,
      desktopInput,
      inputElement,
      completionResults,
      uiAllowsCompletionContainer,
      showCompletionMobile,
      showCompletionDesktop,
      active,
      currentSearchTerm,
      searchResults,
      searchResultsList,
      mainSearchNavButtonPressed,
      searchModalOpen,
      searchWidgetOpen,
      doSearch, 
      handleSearchButtonClick, 
      handleClickOnNonmodalSearchWidget,
      useSearchRoute,
      modalOpen, 
      loading,
      totalWidgetHeight,
      widgetHeight,
      shadowHeight,
      mergeClassesTheme
    }
  },
  components: {
    Modal,
    IconButton,
    SearchResults
  }
});
</script>

<style>
.nav-main-search-searchoption {
  z-index:1;
  padding-left:10px; 
  padding-right:10px; 
  width:60px;
}

.nav-main-search-loading-glass {
  background:#FFFFFF;
  opacity:0.5;
}

.nav-main-search-headline {
  color: #fff;
  font-size: 32px;
}

input.nav-mobile-search-input:focus-visible, input.nav-main-search-input:focus-visible, input.nav-main-search-nonmodal-input:focus-visible {
  outline:none;
}

.nav-mobile-search-completion-ui-container, .nav-main-search-completion-ui-container, .nav-main-search-nonmodal-completion-ui-container {
  top:0%;
  height:100%;
  width:100%;
  margin-top:0px !important;
}
.nav-main-search-nonmodal-completion-wrapper {
  box-shadow: 0px 5px 5px rgba(0,0,0, 0.5); 
}

.nav-mobile-search-completion-wrapper, .nav-main-search-completion-wrapper, .nav-main-search-nonmodal-completion-wrapper  {
  position:absolute;
  background:white; 
  z-index:10000; 
  width:100%;
  top:100%;
}

.nav-main-search-nonmodal-ui-container {
  top:100%;
  width:350px !important;
  left:auto;
  right:0px;
  background:white;
}

.nav-main-search-nonmodal-ui-container {
  box-shadow: 0px 5px 5px rgba(0,0,0, 0.5);
  background:white;
}

.nav-main-search-nonmodal-loading-glass {
  position:absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
}

table.gstl_50.gssb_c {
  display:none !important;
}

</style>

<template>

  <!-- The mobile widget in the sidenav drawer -->
  <div v-if="getProp('isMobile')" :class="mergeClassesTheme('sb sb-v', getProp('navComponent.outerClasses'))">
    <h3 :class="mergeClassesTheme('sb sb-text nav-mobile-search-mobile-title', getProp('navComponent.headlineClasses'))">{getProp('navComponent.headline')}</h3>
    <div :class="mergeClassesTheme('sb sb-v sb-gr nav-mobile-search-ui-container', getProp('navComponent.searchBoxUIContainerClasses'))">
      <div :class="mergeClassesTheme('sb sb-v nav-mobile-search-searchbox-wrapper', getProp('navComponent.searchBoxWrapperClasses'))">
        <input ref="mobileInput" :class="mergeClassesTheme('sb nav-mobile-search-input', getProp('navComponent.searchBoxClasses'))">
      </div>
      <div ref="mobileCompletionWrapper" v-show="showCompletionMobile" :class="mergeClassesTheme('sb sb-abox nav-mobile-search-completion-ui-container', getProp('navComponent.completionUIContainerClasses'))">
        <ul ref="mobileCompletion" :class="mergeClassesTheme('sb-abox sb sb-v sb-gr nav-mobile-search-completion-wrapper', getProp('navComponent.completionWrapperClasses'))">
          <template v-for="result in completionResults">
            <li v-if="result.text" :data-searchtext="result.text" v-html="result.html" :class="mergeClassesTheme('sb sb-text nav-mobile-completetion-result', getProp('navComponent.completionResultClasses'))"></li>
          </template>
        </ul>
      </div>
      <span v-if="loading" :class="mergeClassesTheme('sb-spread nav-mobile-search-loading-glass', getProp('navComponent.loadingGlassClasses'))"></span>
    </div>
    <IconButton ref="mobileSearchButton" class="sb" aria-label="Search icon button" :loading="loading" :allowPropagate="false" :theme="getProp('navComponent.button', 'theme')" @buttonClick="handleSearchButtonClick" />
  </div>


  <!-- The desktop option in the main top-nav -->
  <div v-if="!getProp('isMobile')" :class="mergeClassesTheme('sb sb-explicit nav-main-search-searchoption sb-v sb-align-cv', getProp('navComponent.outerClasses'))">
    <IconButton ref="mainSearchButton" class="sb" aria-label="Search icon button" :allowPropagate="false" :theme="getProp('navComponent.button', 'theme')" @buttonClick="mainSearchNavButtonPressed" />
  
    <!-- if we're not using a modal for the search, then we're going to include it here. -->
    <div v-if="!getProp('searchComponent.modalMode')"
      v-show="searchWidgetOpen"
      @click="handleClickOnNonmodalSearchWidget"
      :class="mergeClassesTheme('sb sb-abox nav-main-search-nonmodal-ui-container', getProp('searchComponent.searchBoxUIContainerClasses'))">      
      
      <div class="sb sb-v sb-gr sb-g10" style="width:100%;">
        <h3 :class="mergeClassesTheme('sb nav-main-search-nonmodal-headline', getProp('searchComponent.mainHeadlineClasses'))" v-if="getProp('searchComponent.mainHeadline')">{{ getProp('searchComponent.mainHeadline') }}</h3>

        <div class="sb sb-h sb-greedy-1"> 
          <div :class="mergeClassesTheme('sb sb-greedy sb-v nav-main-search-nonmodal-searchbox-wrapper', getProp('searchComponent.searchBoxWrapperClasses'))">
            <input ref="desktopInput" :class="mergeClassesTheme('sb nav-main-search-nonmodal-input', getProp('searchComponent.searchBoxClasses'))">
          </div>
          <IconButton :theme="getProp('searchComponent.searchButton', 'theme')" :allowPropagate="false" @buttonClick="handleSearchButtonClick" :loading="loading" />
        </div>

        <div ref="desktopCompletionWrapper" v-show="showCompletionDesktop" :class="mergeClassesTheme('sb sb-abox nav-main-search-nonmodal-completion-ui-container', getProp('searchComponent.completionUIContainerClasses'))">
          <ul ref="desktopCompletion" :class="mergeClassesTheme('sb-abox sb sb-gr sb-v nav-main-search-nonmodal-completion-wrapper', getProp('searchComponent.completionWrapperClasses'))">
            <template v-for="result in completionResults">
              <li v-if="result.text" :data-searchtext="result.text" v-html="result.html" :class="mergeClassesTheme('sb sb-text nav-main-nonmodal-completion-result', getProp('searchComponent.completionResultClasses'))"></li>
            </template>
          </ul>
        </div>

        <span v-if="loading" :class="mergeClassesTheme('sb-spread nav-main-search-nonmodal-loading-glass', getProp('searchComponent.loadingGlassClasses'))"></span>
      </div>

    </div>
  
  </div>


  <!-- The modal for the search widget in desktop scenarios -->
  <Modal v-if="!getProp('isMobile') && getProp('searchComponent.modalMode')" :theme="getProp('searchComponent.modal', 'theme')" v-model="searchModalOpen">
    <template v-slot:headline>
      <h3 v-if="getProp('searchComponent.topHeadline')" :class="mergeClassesTheme('nav-main-top-headline', getProp('searchComponent.topHeadlineClasses'))">{{ getProp('searchComponent.topHeadline') }}</h3>
    </template>
    <template v-slot:body>

      <div class="sb sb-v sb-greedy sb-align-cv">

        <h3 :class="mergeClassesTheme('sb nav-main-search-headline', getProp('searchComponent.mainHeadlineClasses'))" v-if="getProp('searchComponent.mainHeadline')">{{ getProp('searchComponent.mainHeadline') }}</h3>

        <div :class="mergeClassesTheme('sb sb-v sb-align-cv sb-gr nav-main-search-ui-container', getProp('searchComponent.searchBoxUIContainerClasses'))">
          <div :class="mergeClassesTheme('sb sb-v nav-main-search-searchbox-wrapper', getProp('searchComponent.searchBoxWrapperClasses'))">
            <input ref="desktopInput" :class="mergeClassesTheme('sb nav-main-search-input', getProp('searchComponent.searchBoxClasses'))">
          </div>

          <div ref="desktopCompletionWrapper" v-show="showCompletionDesktop" :class="mergeClassesTheme('sb sb-abox nav-main-search-completion-ui-container', getProp('searchComponent.completionUIContainerClasses'))">
            <ul ref="desktopCompletion" :class="mergeClassesTheme('sb-abox sb sb-gr sb-v nav-main-search-completion-wrapper', getProp('searchComponent.completionWrapperClasses'))">
              <template v-for="result in completionResults">
               <li v-if="result.text" :data-searchtext="result.text" v-html="result.html" :class="mergeClassesTheme('sb sb-text nav-main-completion-result', getProp('searchComponent.completionResultClasses'))"></li>
              </template>
            </ul>
          </div>

          <span v-if="loading" :class="mergeClassesTheme('sb-spread nav-main-search-loading-glass', getProp('searchComponent.loadingGlassClasses'))"></span>
        </div>
        <IconButton :theme="getProp('searchComponent.searchButton','theme')" :allowPropagate="false" @buttonClick="handleSearchButtonClick" :loading="loading" />

      </div>

    </template>
  </Modal>


  <!-- The search results modal, for use in either mobile or desktop -->
  <Modal v-if="getProp('resultsComponent.modal')" :suspendScrolling="!inMobile" :theme="getProp('resultsComponent.modal', 'theme')" :googleCSEUsecase="true" v-model="modalOpen">
    <template v-slot:headline>
      <h3>Search</h3>
    </template>
    <template v-slot:body>
      <SearchResults ref="searchResultsList" 
        :theme="getProp('resultsComponent.searchResults', 'theme')" 
        :searchResultsRoute="getProp('resultsComponent.route')"
        :inModal="true">
              
        <template #result-with-thumbnail="{ pagemap, link, title, htmlSnippet }">
          <span class="sb sb-explicit" style="width:150px;">
            <img :src="pagemap.cse_thumbnail[0].src" style="object-fit:contain; width:100%; height:auto;">
          </span>
          <p class="sb sb-greedy sb-text">
            <a :href="link">{{ title || "7 Tribes" }}</a>
            <br>
            <span v-html="htmlSnippet"></span>
          </p>
        </template>

        <template #result-no-thumbnail="{ link, title, htmlSnippet }">
          <p class="sb sb-greedy sb-text">
            <a :href="link">{{ title }}</a>
            <br>
            <span v-html="htmlSnippet"></span>
          </p>
        </template>

        <template v-slot:loading>
          <h1>Loading...</h1>
        </template>

 
        <template #no-results="searchInfo">
          <h1 class="sb">No search results for "{{ searchInfo.searchTerm }}"</h1> 
        </template>

 
        <template #results-found="{ searchTerm, numItems}">
          <h3 class="sb">Found {{ numItems }} {{ numItems > 1 ? 'results' : 'result'}} for "{{ searchTerm }}"</h3> 
        </template>
          
      </SearchResults>
      
    </template>
    
  </Modal>

  
</template>