/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  Random formatted strings

  Author Alex Lowe

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { inBrowser, baseURL, mainMode, appMode } from "@kit/utils/EnvironmentHelper"

export const listDelimiterAPStyle = (which, len) => {

  //no delimiters of only one single element
  if(len == 1) {
    return false
  } 
  
  //an and in-between if two elements
  else 
  if(len == 2) {
    if(which == 0) {
      return " and "
    }
  } else 

  //e.g. lenth of 4.
  //0    1     2          3
  //pear, apple, grapes and bananas
  if(len > 2) {
    if(which == len - 2) {
      return " and "
    } else 
    if(which < len - 2) {
      return ", "
    }
  }

  return false
}

export const contactName = (contact) => {
  const { display_title, post_nominal } = contact 
  const pN = post_nominal ? `, ${post_nominal}` : ''
  return `${display_title}${pN}`
}

/**
 * Make sure that a string ends with an end-character
 * 
 *
 * @param {String, required} sourceString. The source string 
 * @param {String, required} endChar. The ending character to enforce 
 * @returns {String} a new string, or the original string if unaltered.
 * 
 */
export const endChar = (sourceString, endChar, trim) => {
  if(!sourceString) {
    return endChar
  }

  const s = trim ? sourceString.trim() : sourceString
  const last = s[s.length-1];
  if(last == endChar) {
    return s
  } else {
    return `${s}${endChar}`
  }
}


/**
 * 
 * @param {String, required} classes. the default classes for a particular component element
 * @param {Object, optional} themeSlot. the object within the theme:
 *    { append:"stuff" }
 *    or
 *    { override:"stuff" } 
 * 
 *    or 
 *     "stuff"
 *  
 * In the last case, it will be assumed that we're appending and not overriding.
 * 
 * @returns a string with the default, default + classes or classes.
 * 
 */
export const mergeClassesTheme = (classes, themeSlot) => {
  if(!themeSlot) {
    return classes
  }

  if(themeSlot.constructor == String) {
    return `${classes} ${themeSlot}`
  } else 
  if(themeSlot.append) {
    return `${classes} ${themeSlot.append}`
  } else 
  if(themeSlot.override) {
    return themeSlot.override
  }

  return classes
}

export const mergeStylesTheme = (style, themeSlot) => {
  if(!themeSlot) {
    return style
  }

  if(themeSlot.constructor == String) {
    return `${endChar(style,';', true)} ${themeSlot}`
  } else 
  if(themeSlot.append) {
    return `${endChar(style,';', true)} ${themeSlot.append}`
  } else 
  if(themeSlot.override) {
    return themeSlot.override
  }

  return style
}

/**
 * @returns the current year.
 *  
 */
export const getCurrentYear = () => {
  return new Date().getFullYear()
}

/**
 * 
 * @param {*} cDate: a string like 0000-00-00 00:00:00 (from pods date value)
 * @param {*} defVal: the default value in case of an empty date.
 * @returns a nice date like: 3/20/2024
 * 
 */
export const niceDate = (cDate, defVal) => {
  if( cDate && cDate.indexOf('0000-00-00') == -1) {
    var d = new Date(cDate)
    return (d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear()  
  } else {
    return defVal
  }
}


/**
 * Returns the path for a url. Everything after the protocol and domain
 * Take
 *    https://something.com/some-place/image.png
 * or 
 *    @images/some-place/image.png
 * 
 * And this will return 
 *    /some-place/image.png
 * 
 * @param {string} url. required. 
 * @returns the path
 */
export const pathname = (url) => {

  if(url.indexOf("@images") == 0) {
    return url.replace("@images","/assets")
  } else 
  if(url.indexOf("/") == 0) {
    return url
  }

  //trim
  url = url.trim()

  //the http or https
  let protocolLen = 4
  if(url.indexOf("https") > -1) {
    protocolLen = 5
  }
  //the ://
  protocolLen += 3
  
  //localhost or www.something.io
  const noProtocol = url.substr(protocolLen)

  //everything with letters dots dashes and underscores is the subdomain, domain
  //the dot and the top-level-domain. just replace that with the empty string and voila.
  const path = noProtocol.replace(/^[a-zA-Z0-9\-_\.]+/, "")

  //if its empty, then just a slash
  if(path == "") {
    return "/"
  } else {
    return path
  }
}


// Takes a number of messy formats and outputs the correct path to the cms-static-assets 
//
// /cms-static-assets/some-file
// /some-file
// https://localhost/cms-static-assets/some-file
// https://something.com/cms-static-assets/some-file
// localhost/cms-service/wp-content/uploads/2023/03/pexels-burak-the-weekender-186461-resized.png
// 
// Sometimes the outputs from wordpress can be messy.
//
// Turn it into:
// /cms-static-assets/some-file
//

export const cmsasset = (url) => {

  //trim
  url = url.trim()

  let cmsPath = ""

  if(url.indexOf("@images") == 0) {
    throw new Error("cmsasset is not supposed to be used with @images. use pathname")
  } else 
  if(url.indexOf("/cms-static-assets") == 0) {
    cmsPath = url
  } else
  if(url.indexOf("/") == 0) {
    cmsPath = `${baseURL}/cms-static-assets${url}`
  } else {

    //the http or https
    let protocolLen = 4
    if(url.indexOf("https") > -1) {
      protocolLen = 5
    }
    //the ://
    protocolLen += 3

    const noProtocol = url.substr(protocolLen)
    //   localhost/cms-service/wp-content/uploads/2023/03/pexels-burak-the-weekender-186461-resized.png

    const protocol = url.substr(0, protocolLen)
    //   https://

    const m = noProtocol.match(/^[a-zA-Z0-9\-_\.]+/)
    const domain = m[0]
    //   localhost
    
    //everything with letters dots dashes and underscores is the subdomain, domain
    //the dot and the top-level-domain. just replace that with the empty string and voila.
    const barePath = noProtocol.replace(/^[a-zA-Z0-9\-_\.]+/, "")
    //   /cms-service/wp-content/uploads/2023/03/pexels-burak-the-weekender-186461-resized.png
    
    const staticAssets = barePath.replace(/^(.*?)uploads/,"/cms-static-assets")
    //   /cms-static-assets/2023/03/pexels-burak-the-weekender-186461-resized.png
    //cmsPath = `${baseURL}${staticAssets}`
    cmsPath = staticAssets

    //cmsPath = `${protocol}${domain}${staticAssets}`
  }

  return cmsPath
}


/**
 * @param {String} name the name of the parameter to retrieve from the window url, provided it's the google custom search 
 *  string like this: https://localhost/#gsc.tab=0&gsc.sort=&gsc.q=healthy%20dinner .
 *  In this case, the names of the available parameters are "sort" and "q". We ignore "gsc." and the first one, "tab" is ignored.
 *  
 * @returns {String} the value for the parameter
 * 
 */
export const getGCSParameterByName = (name) => {
  const fullURL = inBrowser ? window.location.href : ""
  const split1 = fullURL.split("#gsc.tab=0")
  const split2 = split1.length == 2 ? split1[1] : ""
  const split3 = split2.split("&gsc.")

  //first one is blank, hence i=1
  for(let i=1; i<split3.length; i++) {
    const item = split3[i]
    const itemSplit = item.split("=")
    if(itemSplit.length == 2) {
      const key = itemSplit[0]
      const val = decodeURIComponent(itemSplit[1])
      if(key == name) {
        return val
      }
    }
  }
  return null 
}