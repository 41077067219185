/*
----------------------------------------------------------------------------
--
-- Scroller 
-- 
-- A library to handle scroll effects 
--
-- Author Alex Lowe
--
----------------------------------------------------------------------------

## API function 

  register(".myElement")

Attach the following attribute to the .myElement element

data-scrollfx='{
  "type": "scrollDelayMultiple" or "scrollDelay" 
  "to": (optional. what is the actual animation element this is poing to? default is "self"
    if not "self", then this will be treated as a query-selector applied to the trigger, and the first result will be
    used as the animation target) e.g. ":first-child"
  "rm": (optional. default is 0px) what is the rootMargin to be used for the intersection observer? e.g. "0px -99% 0px 0px"
  "rm_in": (optional. defaults to "rm" setting) if specified, this will be the root-margin for the is-intersecting state
  "rm_out": (optional. defaults to "rm" setting) if specified, this will be the root-margin for the is-not-intersecting state 
  "th": (optional. default is 1) what is the threshold to be used for the intersection observer? e.g. 0.1, 1, 0.5
  "th_in": (optional: defaults to "th" setting) if specified, this will be the threshold for the is-intersecting state e.g. 0.1, 1, 0.5
  "th_out": (optional: defaults to "th" setting) if specified, this will be the threshold for the in-not-intersecting state e.g. 0.1, 1, 0.5
  "an": (optional. default is "t" for translate. other one is "s" for scale) e.g. "t","s"
  "eas": (optional. default is "easeInOutQuad" any other anime.js options will work)
  "debugStyle": (optional. default is null) add a style string to show the scroll trigger 
  "dx": (optional. default is 0) an optional shift amount for the scroll trigger's x position
  "dy": (optional. default is 0) an optional shift amount for the scroll trigger's y position
}'


With a query selector for the elements where your data-scrollfx is defined. 

And for the object, you have these choices:

scrollDelayMultiple--------------------------------------------
The children can have their own configuration objects 

for the parent:
{
  "type":"scrollDelayMultiple", 
  "delay": same as scrollDelay option
  "duration": same as scrollDelay option
  "delta": same as scrollDelay option
  "up": same as scrollDelay option
  "down": same as scrollDelay option
  "unit": same as scrollDelay option
  "targetQuery": optional. the query to select animation targets. if this is is falsy,
      then it will default to just the direct descendants.
}

for the ith child. This is optional
{
  "index": 0  (optional. which order the child gets animated in. default is the order of appearance of the child)
  "duration": same as scrollDelay option (optional. overrides the corresponding option from the parent)
  "delta": same as scrollDelay option (optional. overrides the corresponding option from the parent)
  "up": same as scrollDelay option (optional. overrides the corresponding option from the parent)
  "down": same as scrollDelay option (optional. overrides the corresponding option from the parent)
  "unit": same as scrollDelay option (optional. overrides the corresponding option from the parent)
}

Example:
  <div
    class="my-trigger" style="height: 1px"
    data-scrollfx='{"type":"scrollDelayMultiple", "delay":1000}'
  ></div>
  <ul>
    <li style="flex: 0 0 20%; margin: 20px" data-scrollfx='{"index":0, ("duration":200) }'>Star Wars</li>
    <li style="flex: 0 0 20%; margin: 20px" data-scrollfx='{"index":1, ("duration":300) }'>Star Trek</li>
  </ul>


scrollDelay----------------------------------------------------
{
  "delay": 99, (optional. If num, then it will look for delay. If no delay, then default is 400 (ms))
  "delta": "99%" (optional. The amount of y translation. If none specified, 20%)
  "duration": 99 (optional. The duration of the animation, not counting delay default is 500 (ms))
  "down": (optional. The displacement vector to be used on the object in the event that the object 
    scrolls off-view while the user is scrolling DOWN the page. 
    This can be either an explicit vector like:

      [0,-1]
    
    or it can point to another vector an optionally point to another vector an include a rotation:

      myVec:-45
    
    Or just:

      myVec

    And in these last two cases, the data-scrollfx object must have an vector "myVec". The name is arbitrary, it just has to be there. 
    
    Defaults to [0,-1])
  
  "up": "v:99" (optional. Same as "down", but here it's the displacement vector applied to the animation-target 
    in the event that the animation-target scrolls off-view while the user is scolling UP the page)
  "unit": "px" (optional. defaults to px). The unit of translation if the animation is set up to use translation
}

See this example for the "down" and "up" options

  <div class="sb sb-h sb-explicit ltm-none dr-with-tablet" data-scrollfx='{"type":"scrollDelay", "to":"img", "myV":[0,1], "down":"myV:-45", "up":"myV:45" }' style="width:650px; border:1px solid red;">
    <img src="@images/STHS-Doctor-with-tablet.png" style="height:auto; width:100%; object-fit:contain; object-position:center top;"/>
  </div>

  <div class="sb sb-h sb-explicit ltm-none dr-with-tablet" data-scrollfx='{"type":"scrollDelay", "to":"img", "myV":[0,1], "down":"myV:-45", "up":[-1,0] }' style="width:650px; border:1px solid red;">
    <img src="@images/STHS-Doctor-with-tablet.png" style="height:auto; width:100%; object-fit:contain; object-position:center top;"/>
  </div>


## API function 
 
  registerCallbacks = (rootRef, callbacks) 

  @param {Ref, required} rootRef. The ref to the root of the component
  @param {Object, required} callbacks. The callbacks. 
    {
      animateIn(easing, duration, delay) {
     //do stuff here
      },
       animateOut(easing, duration, delay) {
       //do stuff here
      },
    } 

Use function inside a component to tap into the animation and perform further animations
on the internal parts of the component. For example, this component has a learnMore button 
and we want to animate that as well on another delay. Makes it look a little cooler you see.

  const root = ref(null)
  const learnMore = ref(null)

  onMounted(() => {
    const learnMoreNode = learnMore.value
    learnMoreNode.style.opacity = "0"
    learnMoreNode.style.transform = "translateX(-100px)"

    registerCallbacks(root, {
      animationIn(easing, duration, delay) {
        anime({
          targets: learnMoreNode,
          opacity: 1,
          translateX:0,
          easing,
          duration: 500,
          delay: delay+500
        })
      },
      animationOut(easing, duration, delay) {
        anime({
          targets: learnMoreNode,
          opacity: 0,
          translateX:-100,
          easing,
          duration: 500,
          delay: 0
        })
      },
    })
  })

An edge-case:

If you have a really big and tall area that you want to make scrollable, you'd be well-advised to 
make the th_out and th_in thresholds pretty small, like 0.2. If you make one big like 0.5 you're going 
to have a problem where 0.5 of the scroll area never fits in the screen, therefore the threshold never 
triggers. 
So in that case we make a judgement call. Instead of using 0.5 of the height of the 
scrollable-sensive area, we re-calculate the threshold so that it's 0.5 of the height of the viewport 
instead.
See ALEX-TODO. This is actually hard and bad.


*/

import anime from 'animejs'
import { V2 } from '@kit/utils/Vector'
import { getBoundingClientRect } from '@kit/utils/BoundingClientRect'

const wCtx = typeof window != 'undefined' ? window : null

//There's a couple of places where we have to attach information
//directly to dom-nodes, and these are properties that we'll use.
const DOMEL_PROP0 = "__845ehd7"
const DOMEL_PROP1 = "__pfie73s"

class Scroller {

  static observers = {}
  static numObservers = {}
  static inBrowser = typeof window !== 'undefined'
  static totalListeners = 0
  static listeningForResizes = false
  static firstInstance = null
  static lastInstance = null
  static numInstances = 0
  static dInterval = null
  static dCache = null

  constructor() {
    this.previousY = 0
    this.scrollTriggers = []
    this.prev = null
    this.next = null
    this.destroyed = false
    this.addToChain()
    Scroller.listenForResize()
  }

  addToChain() {
    if(Scroller.numInstances == 0) {
      Scroller.firstInstance = this
      Scroller.lastInstance = this
    } else {

      if(Scroller.lastInstance) {
        Scroller.lastInstance.next = this
      }
      this.prev = Scroller.lastInstance
      Scroller.lastInstance = this
    }
    Scroller.numInstances++
  }
  removeFromChain() {
    if(this.prev) {
      this.prev.next = this.next
    } else {
      Scroller.firstInstance = this.next
    }
    if(this.next) {
      this.next.prev = this.prev
    } else {
      Scroller.lastInstance = this.prev
    }
    Scroller.numInstances--

    if(!Scroller.firstInstance && !Scroller.lastInstance) {
      Scroller.unlistenForResize()
    }
  }

  static separateValue(strng) {
    if (strng.indexOf('px') != -1) {
      var arr = strng.split('px')
      return { val: Number(arr[0]), unit: 'px' }
    } else if (strng.indexOf('%') != -1) {
      var arr = strng.split('%')
      return { val: Number(arr[0]), unit: '%' }
    } else if (strng.indexOf('pt') != -1) {
      var arr = strng.split('pt')
      return { val: Number(arr[0]), unit: 'pt' }
    } else if (strng.indexOf('pc') != -1) {
      var arr = strng.split('pc')
      return { val: Number(arr[0]), unit: 'pc' }
    } else if (strng.indexOf('in') != -1) {
      var arr = strng.split('in')
      return { val: Number(arr[0]), unit: 'in' }
    } else if (strng.indexOf('cm') != -1) {
      var arr = strng.split('cm')
      return { val: Number(arr[0]), unit: 'cm' }
    } else if (strng.indexOf('mm') != -1) {
      var arr = strng.split('mm')
      return { val: Number(arr[0]), unit: 'mm' }
    } else if (strng.indexOf('em') != -1) {
      var arr = strng.split('em')
      return { val: Number(arr[0]), unit: 'em' }
    } else if (strng.indexOf('rem') != -1) {
      var arr = strng.split('rem')
      return { val: Number(arr[0]), unit: 'rem' }
    } else if (strng.indexOf('ex') != -1) {
      var arr = strng.split('ex')
      return { val: Number(arr[0]), unit: 'ex' }
    } else {
      return { val: 0, unit: '' }
    }
  }

  static getChildren(parent) {
    let nodes = parent.childNodes
    let arr = []
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i]
      let nodeType = node.nodeType
      if (nodeType == 1) {
        arr.push(node)
      }
    }
    return arr
  }

  /**
   * A helper function to examine the info and return a vector
   * for a string like v:-45
   * 
   */
  static getVectorFromInfo(which, info, { val }) {
    //v:-45 which vector are we pointing to, and what's the rotation
    //could also be just v
    const vectorSettings = info[which]
    if (!vectorSettings) {
      throw new Error("getVectorFromInfo: no settings found for " + which)
    } else {

      if ((vectorSettings.constructor == Array)) {
        if (vectorSettings.length != 2) {
          throw new Error("getVectorFromInfo: malformed vector for " + which)
        } else
          if (typeof vectorSettings[0] != "number" || typeof vectorSettings[1] != "number") {
            throw new Error("getVectorFromInfo: malformed vector for " + which + ": I was expecting a number.")
          }
        const vector = new V2(vectorSettings[0], vectorSettings[1])
        vector.mag(val)
        return vector
      } else {
        const split = vectorSettings.split(":")
        const v = split[0]
        const rotation = split.length > 1 ? parseFloat(split[1]) : 0
        const vectorFromInfo = info[v]
        if (!vectorFromInfo) {
          throw new Error("getVectorFromInfo: No " + which + " vector found for '" + v + "' in info")
        }
        const vector = new V2(vectorFromInfo[0], vectorFromInfo[1])
        vector.rotate(rotation)
        vector.mag(val)
        return vector
      }
    }
  }

  /**
   * Get the vectors to translate the object
   * from the design position to a new position in the 
   * event that the object is scrolled out of view. 
   * 
   */
  static getVectors(info) {
    let delta = info.delta || "20%"
    let up = new V2(0, -1)
    let down = new V2(0, 1)
    const deltaObj = Scroller.separateValue(delta)

    up.mag(deltaObj.val)
    down.mag(deltaObj.val)

    if (info.up) {
      up = Scroller.getVectorFromInfo("up", info, deltaObj)
    }
    if (info.down) {
      down = Scroller.getVectorFromInfo("down", info, deltaObj)
    }
    const { unit } = deltaObj

    return { up, down, unit }
  }



  static resolveCallbacks(node, inOrOut, easing, duration, delay) {
    const callbacks = node[DOMEL_PROP1]
    if(callbacks) {
      const callback = inOrOut ? callbacks.animationIn : callbacks.animationOut
      if(callback) { 
        callback(easing, duration, delay)
      } 
    }
  }

  static scrollDelayMultipleGetOption(property, node, parentInfo, defaultVal) {

    const defaults = {
      duration:500,
      delta:"20%",
      unit:"px",
      an:"t",
      eas:"easeInOutQuad"
    }

    let childInfo = node[DOMEL_PROP0]
    let infoString = ""

    try {
      if (!childInfo) {
        infoString = node.getAttribute("data-scrollfx")
        childInfo = infoString
          ? JSON.parse(infoString)
          : {}
        node[DOMEL_PROP0] = childInfo
      }
    } catch(e) {
      console.log("Scroller. I Did not like JSON: "+infoString)
      throw new Error("JSON Parse Error. See log above.")
    }

    if(!childInfo) {
      childInfo = {}
      node[DOMEL_PROP0] = childInfo
    }

    //Get all the vector information
    if(property == "vectors") {
      const pVecs = Scroller.getVectors(parentInfo)
      const cVecs = (childInfo.up || childInfo.down || childInfo.delta) ? Scroller.getVectors(childInfo) : null

      const up = childInfo.up ? cVecs.up : pVecs.up 
      const down = childInfo.down ? cVecs.down : pVecs.down 
      const unit = childInfo.unit ? cVecs.unit : pVecs.unit

      return { up, down, unit }
    } 
    //else, just return the property, respecting the override power of the child info, and also 
    //the optional defaultValue
    else {
      const v1 = childInfo[property] || parentInfo[property]
      if(v1 !== undefined) {
        return v1
      } else
      if(defaultVal !== undefined) {
        return defaultVal
      }
      return defaults[property] || null
    }
  }


  static fxCallbacks = {

      simpleAsync: {
        intersectsYes: async(animationTarget, scrollDirection, info, xHit, xMiss) => {
          await info.intersectOn(info)
        },
        intersectsNo: async(_animationTarget, _scrollDirection, info, _xHit, _xMiss) => {
          await info.intersectOff(info)
        }
      },
      simpleSync: {
        intersectsYes: async(animationTarget, scrollDirection, info, xHit, xMiss) => {
          info.intersectOn(info)
        },
        intersectsNo: (_animationTarget, _scrollDirection, info, _xHit, _xMiss) => {
          info.intersectOff(info)
        }
      },

      asyncAction: {

        intersectsYes: async(animationTarget, scrollDirection, info, xHit, xMiss) => {

          if (info["_motion"]) {
            info["_cache"] = { "which": "intersectsYes", "target": animationTarget, scrollDirection }
            return
          }

          //if we've intersected for the first time, then we're going to perform the action.
          if(xHit == 1) {
            info["_motion"] = true
            await info.action(info)
            info["_motion"] = false
          }
        },
        intersectsNo: (_animationTarget, _scrollDirection, _info, _xHit, _xMiss) => {
        }

      },

      scrollDelayMultiple: {
        intersectsYes: (animationTarget, scrollDirection, info, xHit, xMiss) => {
          if (info["_motion"]) {
            info["_cache"] = { "which": "intersectsYes", "target": animationTarget, scrollDirection }
            return
          }

          //If we have a target-query, then use that to get all of the children.
          //else, just use the children. This is so that the scroller target can have a complex
          //structure, which is sometimes unavoidable in the messy world of html.
          let arr = info.targetQuery ? animationTarget.querySelectorAll(info.targetQuery) : Scroller.getChildren(animationTarget)
          
          if(!arr) {
            return
          }
          
          let len = arr.length

          info["_motion"] = true
          let numComplete = 0

          for (let i = 0; i < len; i++) {
            let node = arr[i]
            let duration = 0
            const index = Scroller.scrollDelayMultipleGetOption("duration", node, info, i)

            //See the notes in the callback function for more on this condition
            if(xHit == 1 && xMiss == 0) {
              duration = 0
            } else {
              duration = Scroller.scrollDelayMultipleGetOption("duration", node, info)
            }

            let totalDelay = info.delay || 400
            //calculate when this child element is supposed to animate in. 
            //it's supposed to wait for the other ones ahead
            let delay = len > 1 ? totalDelay * (index / (len - 1)) : totalDelay

            let animationProps = {}
            const an = Scroller.scrollDelayMultipleGetOption("an", node, info)
            const easing = Scroller.scrollDelayMultipleGetOption("eas", node, info)

            if(an == "t") {
              animationProps = { translateX: "0%", translateY: "0%", opacity:1 }
            } else if(an == "s") {
              animationProps = { scaleX:1, scaleY:1 }
            }
      
            anime({
              targets: node,
              ...animationProps,
              easing,
              complete: (_a) => {
                numComplete++
                if (numComplete == len) {
                  Scroller.doCache(info)
                }
              },
              duration,
              delay,
            })

            Scroller.resolveCallbacks(node, true, easing, duration, delay)
          }
        },
        intersectsNo: (animationTarget, scrollDirection, info, xHit, xMiss) => {
          if (info["_motion"]) {
            info["_cache"] = { "which": "intersectsNo", "target": animationTarget, scrollDirection }
            return
          }

          //If we have a target-query, then use that to get all of the children.
          //else, just use the children. This is so that the scroller target can have a complex
          //structure, which is sometimes unavoidable in the messy world of html.
          let arr = info.targetQuery ? animationTarget.querySelectorAll(info.targetQuery) : Scroller.getChildren(animationTarget)
          
          if(!arr) {
            return
          }
          let len = arr.length

          info["_motion"] = true
          let numComplete = 0


          for (let i = 0; i < len; i++) {
            let node = arr[i]
            let duration = 0

            //See the notes in the callback function for more on this condition
            if(xHit == 1 && xMiss == 0) {
              duration = 0
            } else {
              duration = Scroller.scrollDelayMultipleGetOption("duration", node, info)
            }

            let animationProps = {}
            const an = Scroller.scrollDelayMultipleGetOption("an", node, info)
            const easing = Scroller.scrollDelayMultipleGetOption("eas", node, info)

            if(an == "t") {
              const { down, up, unit } = Scroller.scrollDelayMultipleGetOption("vectors", node, info)
              
              //scrolling down: scrollDirection = false
              //scrolling up: scrollDirection = true
              if (!scrollDirection) {
                animationProps = { translateX: down.i + unit, translateY: down.j + unit,  opacity: 0  }
              } else {
                animationProps = { translateX: up.i + unit, translateY: up.j + unit,   opacity: 0  }
              }

            } else if(an == "s") {
              animationProps = { scaleX:0, scaleY:0 }
            }

            anime({
              targets: node,
              ...animationProps,
              easing,
              duration,
              complete: (_a) => {
                numComplete++
                if (numComplete == len) {
                  Scroller.doCache(info)
                }
              }
            })
            Scroller.resolveCallbacks(node, false, easing, duration, 0)

          }
        },
      },
    
      scrollDelay: {
        intersectsYes: (animationTarget, scrollDirection, info, xHit, xMiss) => {

          if (info["_motion"]) {
            info["_cache"] = { "which": "intersectsYes", "target": animationTarget, scrollDirection }
            return
          }

          info["_motion"] = true

          let duration = info.duration || 500
          let totalDelay = info.delay || 400
          let num = info.num
          let delay = 0
          let index = info.index || 0

          if (num) {
            delay = totalDelay * (index / (num - 1))
          }

          //See the notes in the callback function for more on this condition
          if(xHit == 1 && xMiss == 0) {
            duration = 0
          }

          let animationProps = {}
          const an = info["an"] || "t"
          
          if(an == "t") {
            animationProps = { translateX: "0%", translateY: "0%", opacity:1 }
          } else if(an == "s") {
            animationProps = { scaleX:1, scaleY:1 }
          }

          const easing = info["eas"] || "easeInOutQuad"

          anime({
            targets: animationTarget,
            ...animationProps,
            easing,
            duration,
            delay,
            complete: (_a) => {
              Scroller.doCache(info)
            }
          })
          Scroller.resolveCallbacks(animationTarget, true, easing, duration, delay)

        },


        /** 
         * We look at the info and we use it to calculate two displacement vectors
         * to be used on the object in event that it scrolls off-viewport. There's 
         * a displacement vector to be used if the user is scrolling UP the page, 
         * i.e. the "up" vector and the displacement vector to be used if the user 
         * is scrolling DOWN the page i.e. the "down" vector. 
         * 
         * Note that we don't bother these vectors for cases where the object has 
         * scrolled in-viewport. For those cases, we're just doing to reset the 
         * translation to 0, i.e. we're just resetting the object to it's original
         * design position, which we take to be the "visible" position.
         * 
         */
        intersectsNo: (animationTarget, scrollDirection, info, xHit, xMiss) => {

          if (info["_motion"]) {
            info["_cache"] = { "which": "intersectsNo", "target": animationTarget, scrollDirection }
            return
          }

          info["_motion"] = true

          let animationProps = {}
          let duration = info.duration || 500
          const an = info["an"] || "t"
          let easing = info["eas"] || "easeInOutQuad"

          //See the notes in the callback function for more on this condition
          if(xHit == 1 && xMiss == 0) {
            duration = 0
          }

          if(an == "t") {
            const { down, up, unit } = Scroller.getVectors(info)

            //scrolling down: scrollDirection = false
            //scrolling up: scrollDirection = true
            if (!scrollDirection) {
              animationProps = { translateX: down.i + unit, translateY: down.j + unit,  opacity: 0  }
            } else {
              animationProps = { translateX: up.i + unit, translateY: up.j + unit,   opacity: 0  }
            }

          } else if(an == "s") {
            animationProps = { scaleX:0, scaleY:0 }
            easing = "linear"
          }

          anime({
            targets: animationTarget,
            ...animationProps,
            easing,
            duration,
            complete: (_a) => {
              Scroller.doCache(info)
            }
          })
          Scroller.resolveCallbacks(animationTarget, false, easing, duration, 0)

        },
      },
  
    }



  static doCache = (info) => {
    info["_motion"] = false
    const cache = info["_cache"]
    if (cache) {
      const fxType = info.type
      const { which, scrollDirection, target } = cache
      const fxCallback = Scroller.fxCallbacks[fxType]

      info["_cache"] = null

      fxCallback[which].call(
        this,
        target,
        scrollDirection,
        info
      )
    }
  }

  static callback(entry, symmetric, inOrOut) {

    const { target } = entry

    let fxInfo = target[DOMEL_PROP0]

    if(!fxInfo) {
      return
    }

    let fxDataString = target.getAttribute("data-scrollfx")
    const actualElement = fxInfo["element"]

    //next sibling 
    const pointsTo = fxInfo["to"] || "self"
    let animationTarget = null

    if (pointsTo == "self") {
      animationTarget = actualElement
    } else {
      const qResult = actualElement.querySelector(pointsTo)
      if (qResult.length == 0) {
        throw new Error("Scroller: trying to get the animation target point to by " + fxDataString + ", but I can't find anything.")
      }
      animationTarget = (qResult.constructor == Array) ? qResult[0] : qResult
    }

    if (!animationTarget) {
      throw new Error("Scroller. Unknown error. animationTarget is missing")
    }

    let currentY = entry.boundingClientRect.y
    //let currentRatio = entry.intersectionRatio
    let isIntersecting = entry.isIntersecting

    //true:  The user is scrolling UP the page, which is to say that the page is moving down  
    //false: The user is scrolling DOWN the page, which is to say that the page is moving up
    let scrollDirection = true

    //Scrolling down/up
    //Ok- so it's like in independence day when they accidentally go in reverse
    //and Will Smith reverses the instruction sheet taped to the console.  
    if (currentY < this.previousY) {
      //The page is moving UP, therefore we- the user- are scrolling down the page
      scrollDirection = false
    } else if (currentY > this.previousY && isIntersecting) {
      //the page is moving DOWN, therefore we- the user- are scrolling up the page
      scrollDirection = true
    }

    let fxType = fxInfo.type
    let fxCallback = Scroller.fxCallbacks[fxType]

    //We have these numbers here to keep track of the different states for the intersections and non-intersections.
    //If we haven't had any intersections (xHit) then xHit is zero. If we've had exactly 1, i.e. this is the first 
    //intersection, then xHit is 1. If we've had more than 1, then xHit is 2. Same with xMiss.
    //What's the point of this, you ask. We have specific use-cases in the different scrolling modes,
    //like this: We want a pretty little picture an pop up when you scroll to it. Aha, but if it's
    //already on the view-area right when the page is loaded we don't want it to animate, we want it
    //to just be there. So how can we tell whether that's the case? The xHit and xMiss counts give 
    //us a lot of ability to perform that logic. If xMiss is 1, then we've intersected once when the 
    //page loads, but if at the same time xMiss is 0, then it hasn't scrolled off the screen once yet and
    //this means that our condition is met and the pretty little picture is just sitting in the viewable
    //area right from the word go.
    let xHit = fxInfo["_xHit"] || 0
    let xMiss = fxInfo["_xMiss"] || 0

    //observers can be symmetric meaning that they handle 
    //both is-intersecting and is-not-intersection. Others
    //are asymmetric, meaning that they only handle one either 
    //intersection or not-intersecting. 
    //The point of this is that you can specify different rootMargins
    //and thresholds depending on the data-string attached to the node.
    if (isIntersecting) {
      if(symmetric || inOrOut) { 

        if(xHit < 2) {
          xHit++
        }
        fxInfo["_xHit"] = xHit

        fxCallback.intersectsYes.call(
          this,
          animationTarget,
          scrollDirection,
          fxInfo,
          xHit,
          xMiss
        )
      }
    } else {
      if(symmetric || !inOrOut) {

        if(xMiss < 2) {
          xMiss++
        }
        fxInfo["_xMiss"] = xMiss

        fxCallback.intersectsNo.call(
          this,
          animationTarget,
          scrollDirection,
          fxInfo,
          xHit,
          xMiss
        )
      }
    }
  }


  /**
   * @param {string} rootMargin (required) The root margin string for the intersection observer
   * This has to jive with the page design and you just have to tweak it. 
   * "0px 0px -10% 0px"
   * 
   * @param {*} threshold (required) the threshold for the intersection observer 
   */
  static makeScrollObserver(rootMargin, _threshold, symmetric, inOrOut, heightOfArea) {

    //If symmetric, then threshold is the threshold number, and so they're the same.
    //else, threshold is an object that we'll deconstruct.
    const { thIn, thOut } = symmetric ? {thIn:_threshold, thOut:_threshold } : _threshold
    let threshold = inOrOut ? thIn : thOut

    //ALEX-TODO this worked well but sadly there's some problems with this
    //For one we run into problems with the composite string piling this into the 
    //same observer as something else even though we're rewriting the threshold 
    //The worse problem is that now this threshold is changing when the window changes size.
    //That's the really bad one because it means we'd have to rebuild the intersection observer
    //on resize. What a pain. I don't quite know how to mitigate this.
    // //If you have a really big and tall area that you want to make scrollable, you'd be well-advised to 
    // //make the th_out and th_in thresholds pretty small, like 0.2. If you make one big like 0.5 you're going 
    // //to have a problem where 0.5 of the scroll area never fits in the screen, therefore the threshold never 
    // //triggers. 
    // //So in that case we make a judgement call. Instead of using 0.5 of the height of the 
    // //scrollable-sensive area, we re-calculate the threshold so that it's 0.5 of the height of the viewport 
    // //instead.
    // const viewportHeight = window.innerHeight
    // if(threshold * heightOfArea > viewportHeight) {

    // //Ok, so we're going to use the the viewport height as the basis
    // //for the threshold. So let's say the threshold is 0.5. So what we do is we
    // //say 0.5 * the viewportHeight. So we want it to perform the animation when 
    // //half the viewport is showing
    // let thHeightOfViewport = threshold * viewportHeight

    // //so we have this adjusted height. So we divide by the height of area to 
    // //recalculate the threshold.
    // threshold = thHeightOfViewport/heightOfArea
    // }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {

          if(symmetric) {
          Scroller.callback(entry, symmetric, entry.isIntersecting, rootMargin, threshold)
          } else
          if(inOrOut && entry.isIntersecting) {
          Scroller.callback(entry, symmetric, inOrOut, rootMargin, threshold)
          } else 
          if(!inOrOut && !entry.isIntersecting) {
          Scroller.callback(entry, symmetric, inOrOut, rootMargin, threshold)
          } 
          
          //there's an edge-case that presents itself when the out threshold is greater 
          //then the in threshold. Say out:0.5 and in:0.2 you scroll out so that 0.5 of the
          //thing is intersecting. The out threshold triggers, and the thing animates out.
          //But then if you scroll back in just a bit, you'd expect the in-threshold to 
          //activate because clearly more than 0.2 of the thing is showing since 0.5 > 0.2.
          //yet, this doesn't happen. you have to scroll the whole thing out and then back in,
          //and THEN the 0.2 in-threshold triggers. Well, that's no good. so this edge-case 
          //is to keep track of those situations.
          else 
          if(!inOrOut && entry.isIntersecting && thOut > thIn) {
          Scroller.callback(entry, symmetric, true, rootMargin, threshold)
          }

        })
      },
      {
        rootMargin,
        root: null,
        threshold
      }
    )
    return observer
  }

  /**
   * @param {*} fxInfo
   * 
   * This will inspect the info and see if we need to make a new intersection observer object
   *  
   */

  static handleSubtract(composite, pxy) {

    if(Scroller.numObservers[composite] > 0) {
      Scroller.numObservers[composite]--

      const observer = Scroller.observers[composite]
      if(observer) {
        observer.unobserve(pxy)
      }
        
      if(Scroller.numObservers[composite] == 0) {
        delete Scroller.observers[composite]
        Scroller.observers[composite] = null
      }

    }

  }
  static handleAdd(composite, pxy, rootMargin, threshold, symmetric, inOrOut, heightOfArea) {

    if(!Scroller.observers[composite]) {
      Scroller.observers[composite] = Scroller.makeScrollObserver(rootMargin, threshold, symmetric, inOrOut, heightOfArea)
      Scroller.numObservers[composite] = 0
    } 

    const observer = Scroller.observers[composite]
    Scroller.numObservers[composite]++
    observer.observe(pxy)

  }

  static processScrollInfoObj(fxInfo, addOrSubtract, heightOfArea) {

    const rootMargin = fxInfo["rm"] || "0px"
    const threshold = fxInfo["th"] || 1.0
    const composite = `${rootMargin}_${threshold}_1`
    let compositeIn = ''
    let compositeOut = ''
    let asymmetric = false
    let thIn, thOut, rmIn, rmOut = null
    const pxy = fxInfo["_pxy"]

    const {th_in, th_out, rm_in, rm_out } = fxInfo

    if(th_in != undefined || th_out != undefined || rm_in != undefined || rm_out != undefined) {
      asymmetric = true

      thIn = th_in != undefined ? th_in : threshold
      thOut = th_out != undefined ? th_out : threshold
      rmIn = rm_in != undefined ? rm_in : rootMargin
      rmOut = rm_out != undefined ? rm_out : rootMargin

      compositeIn = `${rmIn}_${thIn}_1`
      compositeOut = `${rmOut}_${thOut}_0`

    }

    if(!asymmetric) {

      //If we're adding one  then we may need to create a new observer 
      //or piggyback off of one that already exists
      if(addOrSubtract) {
        Scroller.handleAdd(composite, pxy, rootMargin, threshold, true, true, heightOfArea)
      } 
      
      //If we're subtracting one, then we're going to see if there's 
      //any listeners left on the observer, and get rid of it if not.
      else {
        Scroller.handleSubtract(composite, pxy)
      }

    } else {

      if(addOrSubtract) {

        Scroller.handleAdd(compositeIn,  pxy, rmIn,  {thIn, thOut}, false, true, heightOfArea)
        Scroller.handleAdd(compositeOut, pxy, rmOut, {thIn, thOut}, false, false, heightOfArea)

      } else {

        Scroller.handleSubtract(compositeIn, pxy)
        Scroller.handleSubtract(compositeOut, pxy)

      }

    }

  }


  async debounced(_value) {
    if(this.destroyed) {
      return
    }

    for(let i=0; i<this.scrollTriggers.length; i++) {

      const { trigger, pxy, fxInfo } = this.scrollTriggers[i]
      const { width, height} = await getBoundingClientRect(trigger, true)
      
      //for debugging
      const background = fxInfo.debugStyle ? `z-index:2000; ${fxInfo.debugStyle}` : "background:none;"
      const dx = fxInfo.dx || 0
      const dy = fxInfo.dy || 0

      //We use offsetTop and offsetLeft because we're prepending them to the parent,
      //and the top and left from the client rect will be in client-coords, whereas we need them
      //to be in coords relative to the parent. This is a reflow, but not a bad one.
      pxy.setAttribute("style",`z-index:-1; position:absolute; display:inline; ${background} top:${trigger.offsetTop+dy}px; left:${trigger.offsetLeft+dx}px; width:${width}px; height:${height}px;`)
    
    }
  }

  static debounceInstances(value) {
    let inst = Scroller.firstInstance
    while(inst) {
      inst.debounced(value)
      inst = inst.next
    }
  }

  static debouncer(value) {
    if(!Scroller.dInterval) {
      Scroller.debounceInstances(value)

      Scroller.dInterval = setInterval(() => {
        if(Scroller.dCache !== null) {
          Scroller.debounceInstances(Scroller.dCache)
          Scroller.dCache = null
        } else {
          clearInterval(Scroller.dInterval)
          Scroller.dInterval = null
        }
      },500)
      
    } else {
      Scroller.dCache = value
    }
  }


  static screenResized() {
    Scroller.debouncer(true)
  }

  static listenForResize() {
    if(Scroller.inBrowser && !Scroller.listeningForResizes) {
      Scroller.listeningForResizes = true
      if(wCtx) {
        wCtx.addEventListener('resize', Scroller.screenResized, { passive:true })
      }
      Scroller.screenResized()
    }
  }

  static unlistenForResize() {
    if(Scroller.inBrowser && Scroller.listeningForResizes) {
      Scroller.listeningForResizes = false
      if(wCtx) {
        wCtx.removeEventListener('resize', Scroller.screenResized, { passive:true })
      }
    }
  }

/////////////
//         //
//  A P I  //
//         //
/////////////


  /**
   * @param {string, DOMNode} querySelectorOrNode (required) the query selector or element to trigger the scroll effect.
   * 
   */
  async register(querySelectorOrNode, fxOptionsObj) {

    if(this.destroyed) {
      return
    }
    
    if (Scroller.inBrowser) {

      let whichOnes = typeof querySelectorOrNode == "string" 
        //if string, then we'll treat it as a query selector
        ? document.querySelectorAll(querySelectorOrNode)
        //else, treat it as a node
        : [querySelectorOrNode]

      for (let i=0; i<whichOnes.length; i++) {

        const trigger = whichOnes[i]      
        const triggerParent = trigger.parentNode;

        const pxy = document.createElement("div")
        const { width, height} = await getBoundingClientRect(trigger, true)

        const fxDataString = trigger.getAttribute("data-scrollfx")
        let fxInfo = null 

        try {
          if(fxDataString) {
            fxInfo = JSON.parse(fxDataString)
          } else 
          if(fxOptionsObj) {
            fxInfo = { ...fxOptionsObj }
          }
        } catch(err) {
          //swallow the error
        }

        if (!fxInfo) {
          throw new Error(
            "Whoops. Malformed animation data for this element: " + fxDataString + " here's the querySelectorOrNode: " + querySelectorOrNode
          )
        }

        const dx = fxInfo.dx || 0
        const dy = fxInfo.dy || 0
        const background = fxInfo.debugStyle ? `z-index:2000; ${fxInfo.debugStyle}` : "background:none;"
   
        //We use offsetTop and offsetLeft because we're prepending them to the parent,
        //and the top and left from the client rect will be in client-coords, whereas we need them
        //to be in coords relative to the parent. This is a reflow, but not a bad one.
        pxy.setAttribute("style",`z-index:-1; position:absolute; display:inline; ${background} top:${trigger.offsetTop + dy}px; left:${trigger.offsetLeft + dx}px; width:${width}px; height:${height}px;`)
        
        
        triggerParent.insertBefore(pxy,trigger)

        fxInfo["uid"] = i
        fxInfo["_pxy"] = pxy    
        this.scrollTriggers.push({trigger,pxy,fxInfo})
  
        pxy[DOMEL_PROP0] = fxInfo
        fxInfo["element"] = trigger

        //Add in the listener
        Scroller.processScrollInfoObj(fxInfo, true, height)
      }
      
    }
    
  }

  /**
   * @param {*} domNode 
   * the dom node to unregister
   */
  unregisterNode(domNode) {
    if (Scroller.inBrowser) {

      const scrollTriggers = this.scrollTriggers;

      //loop through. Loop through the whole list, because a node can appear here more than once.
      for(let i=0; i<scrollTriggers.length; i++) {
        const { trigger, fxInfo } = scrollTriggers[i]
        if(trigger == domNode) {
          if(!fxInfo) {
            throw new Error("No fxInfo found for domNode")
          }
          Scroller.processScrollInfoObj(fxInfo, false, null)
          scrollTriggers.splice(i, 1);
          i--
        }
      }
      //

    }
   
  }
  


  /**
   * @param {string, DOMNode} querySelectorOrNode (required) the query selector or element to trigger the scroll effect.
   * 
   * This will just erase the info object attached to the dom-node.
   * This is specifically meant for our lazy-loader component so that it behaves properly
   * when you navigate from one page to another.
   * 
   */
  destroy() {

    if(this.destroyed) {
      return
    }
    this.destroyed = true
    
    if (Scroller.inBrowser) {

      const scrollTriggers = this.scrollTriggers;

      for(let i=0; i<scrollTriggers.length; i++) {
        const { trigger, fxInfo } = scrollTriggers[i]
        Scroller.processScrollInfoObj(fxInfo, false, null)
      }

      this.removeFromChain()

      this.scrollTriggers = []
      this.scrollTriggers = null
      this.prev = null 
      this.next = null

    }
    
  }

}

export default Scroller

/**
 * @param {Ref, required} rootRef. The ref to the root of the component
 * @param {Object, required} callbacks. The callbacks. 
 *   {
 *     animateIn(easing, duration, delay) {
 *      //do stuff here
 *     },
  *     animateOut(easing, duration, delay) {
 *      //do stuff here
 *     },
 *   } 
 */
export const registerCallbacks = (rootRef, callbacks) => {
  if(rootRef.value) {
    const domNode = rootRef.value.$el || rootRef.value
    domNode[DOMEL_PROP1] = callbacks
  }
}


//////////////////////////////////////////////////////////
//                                                      //
//  Extra scroll utils                                  //
//                                                      //
//  Helpful functions for turning scrolling on and off  //
//                                                      //
//////////////////////////////////////////////////////////


//The cached scrolling position, so that it doesn't go back to the top 
//of the page when you turn the scroll back on
let currentScrollPosition = 0

/**
 * Set and get the scroll position.
 * https://stackoverflow.com/questions/4096863/how-to-get-and-set-the-current-web-page-scroll-position
 */
export const getBodyScroll = () => {
  return document.documentElement.scrollTop || document.body.scrollTop
}
export const setBodyScroll = (scrollPosition) => {
  document.documentElement.scrollTop = document.body.scrollTop = scrollPosition;
}


let savedScroll = -1
let usePaddingRightCorrection = false
let paddingRightCorrection = "15px"
let defaultPaddingRight = "0px"
let applyFixedToElement = null
let applyFixedDefaultPosition = "relative"


/**
 * 
 * @param {function, optional} beforeCallback
 * a callback that fires before the scrolling is turned on
 * 
 * @param {function, optional} afterCallback
 * a callback that fires after the scrolling is turned on
 *  
 */
export const bodyScrollOn = (obj) => {
  if(wCtx) {

    const { beforeCallback, afterCallback } = obj ? obj : {}

    //if this callback, then call
    if(beforeCallback) {
      beforeCallback()
    }

    //turn scrolling back on.
    document.documentElement.style.overflow = 'auto';  // firefox, chrome
    document.body.style.position = "static"; 

    //if we applied the fixed style to another element, then rever that here as well.
    if(applyFixedToElement) {
      applyFixedToElement.style.position = applyFixedDefaultPosition
    }

    //if we're correcting the body padding, then reset it.
    if(usePaddingRightCorrection) {
      document.body.style.paddingRight = defaultPaddingRight
    }

    //reset the body top, and then reset the body scroll.
    document.body.style.top = 0;
    if(savedScroll > -1) {
      setBodyScroll(savedScroll)
    } 

    //if this callback, then call
    if(afterCallback) {
      afterCallback()
    }

  }
}

/**
 * 
 * @param {boolean, optional} saveScroll.
 *   If true, this will save the scrolling position and reapply it when we turn the 
 *   scrolling back on.
 *  
 * @param {boolean, optional} correctRight.
 *   If true, this will apply a padding to the right of the body to account for the
 *   disappearance of the scroller bar and this is so that we don't get an annoying
 *   jump effect when turn scrolling off
 * 
 * @param {selector-string, optional} fixed.
 *   Some layouts have the main content element as the only child of the body, and
 *   it's that main element that scrolls around, not the body. Common in modern-js
 *   applications. In those cases, you can pass in a selector-string into 
 *   this slot and it will find that element and apply a fixed style to it, and 
 *   naturally revert that style when we turn scrolling back on
 * 
 */
export const bodyScrollOff = (obj) => {
  if(wCtx) {

    const {saveScroll, correctRight, fixed} = obj ? obj : {}
    savedScroll = saveScroll ? getBodyScroll() : -1

    //some layouts have the main scrolling element as some inner element 
    //which is the first and only child of the body.
    if(fixed) {
      applyFixedToElement = applyFixedToElement || document.querySelector(fixed) || null
      if(applyFixedToElement) {
        applyFixedToElement.style.position = "fixed"
      }
    } else {
      applyFixedToElement = null
    }

    //turn scrolling off.
    document.documentElement.style.overflow = 'hidden'  // firefox, chrome
    document.body.style.position = "fixed"

    //There's a bug that happens under some circumstances in mobile safari where
    //the position:fixed causes the width to get screwed up.
    document.body.style.width = "100%"
    
    //set the top so that it matches the scroll.
    if(saveScroll) {
      document.body.style.top = -savedScroll; 
    }
    
    //This is for the scrollbar, which disappears when we turn off scrolling,
    //but we don't want the page's layout to be screwed up
    //This doesn't look good enough.
    // if(correctRight) {
    //   usePaddingRightCorrection = true
    //   document.body.style.paddingRight = paddingRightCorrection
    // } else {
      usePaddingRightCorrection = false
    //}

    //document.body.scroll = "no"; // ie only. old. not using this
  }
}
